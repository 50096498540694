import $ from '../core/Dom';

export default el => {

    const dom = $(el);
    const menuButton = $('[data-menu-button]');

    let isOpen = false;

    const keyHandler = e => {
        const key = e.keyCode || e.which;
        if (isOpen && key === 27) {
            close();
        }
    };

    const open = (e, data) => {
        dom.addClass('open');
        menuButton.html('&nbsp;Lukk&nbsp;');
        isOpen = true;
        document.addEventListener('keyup', keyHandler);
    };

    const close = () => {
        dom.removeClass('open');
        menuButton.html('Meny');
        isOpen = false;
        document.removeEventListener('keyup', keyHandler);
    };

    const toggle = e => {
        e.preventDefault();
        if (isOpen) {
            close();
        } else {
            open();
        }
    };

    const init = () => {
        menuButton.on('click', toggle);
    };

    const destroy = () => {
        menuButton.off('click', toggle);
        document.removeEventListener('keyup', keyHandler);
    };

    return {
        init,
        destroy
    };
};
