import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import * as Events from '../lib/events';

const loadMapbox = require('bundle-loader?lazy&name=[name]!mapbox-gl');

export default (el, props) => {

    const { apiKey } = props;
    const data = props.markers;
    const centerLatitude = parseFloat(props.centerLat);
    const centerLongitude = parseFloat(props.centerLng);
    const zoomLevel = parseInt(props.zoomLevel, 10);
    let markers = [];

    const getIconElement = type => {
        const icon = $(`#touchpoint-${type}`).get(0);
        const clone = icon.cloneNode(true);
        clone.removeAttribute('id');
        return clone;
    };

    const getPopupOffset = () => (Viewport.width >= 1200 ? -50 : -30);

    const onFilterMarkers = (e, d) => {
        if (d.target === el) {
            markers.forEach(marker => {
                const icon = marker.getElement();
                $(icon).css('display', marker.type === d.type || d.type === 'all' ? 'block' : 'none');
            });
        }
    };

    const init = () => {
        loadMapbox(mapboxgl => {
            mapboxgl.accessToken = apiKey;

            const map = new mapboxgl.Map({
                container: el,
                scrollZoom: false,
                style: 'mapbox://styles/nyekjeller/cl8ei0nle000u14nvsrkjz28v',
                center: [centerLongitude, centerLatitude],
                zoom: zoomLevel,
                attributionControl: false
            }).addControl(new mapboxgl.AttributionControl({
                compact: true
            }));

            const nav = new mapboxgl.NavigationControl();
            map.addControl(nav, 'top-right');

            const popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
            });

            const showLabel = (location, marker) => {
                popup.setLngLat(location).setOffset([0, getPopupOffset()]).setHTML(marker.title).addTo(map);
            };

            const hideLabel = () => {
                popup.remove();
            };

            markers = data.map(m => {
                const icon = getIconElement(m.type);
                const marker = new mapboxgl.Marker({ element: icon, occludedOpacity: 1 });
                const location = [m.longitude, m.latitude];
                marker.setLngLat(location);
                marker.type = m.type;
                icon.addEventListener('mouseenter', () => showLabel(location, m));
                icon.addEventListener('focus', () => showLabel(location, m));
                icon.addEventListener('mouseleave', hideLabel);
                icon.addEventListener('blur', hideLabel);
                icon.addEventListener('click', e => {
                    Dispatch.emit(Events.POPUP_OPEN, { id: m.id, button: icon });
                });
                marker.addTo(map);
                return marker;
            });

            Dispatch.on(Events.TOUCHPOINT_FILTER, onFilterMarkers);
        });
    };

    const destroy = () => {
        Dispatch.off(Events.TOUCHPOINT_FILTER, onFilterMarkers);
    };

    return {
        init,
        destroy
    };
};
